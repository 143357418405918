<template>
  <div style="padding: 50px;">
    desktop
  </div>
</template>
<script>
export default {
  computed: {
    environment () {
      return 'players'
    },
  },
  methods: {
  },
}
</script>

